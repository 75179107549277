/* jscpd:ignore-start */
import { skipWhile, takeUntil, tap, timeout, catchError } from "rxjs/operators";
import {
	Injector,
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import Big from "big.js";
import {
	IDestructionLot,
	UserProfile,
	IInventory,
} from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { UpdateReasonComponent } from "../generic/update-reason.component";

/* jscpd:ignore-end */
import {
	InventorDestructionUpdateSchema,
	InventoryDestructionUpdate,
} from "./schemas/inventory-destruction-update";

@Component({
	selector: "es-inventory-destruction-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryDestructionUpdateComponent
	extends GenericUpdateComponent<InventoryDestructionUpdate>
	implements OnInit, OnDestroy
{
	event: any;
	destruction_lot: IDestructionLot;

	form_title = "Update a Destruction Event";
	form_title_translation_key: string = marker(
		"form_title_update_a_destruction_event",
	);

	submit_button = "Update";
	submit_button_translation_key: string = marker("word_update");
	submit_icon = "edit";

	model: any = {};

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	user: UserProfile;
	user$ = this._store.select(fromDashboard.getProfile);

	schema: any = InventorDestructionUpdateSchema();

	constructor(
		_store: Store<fromDashboard.State>,
		_cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});
	}

	valid(val) {
		this.valid$.next(val);
	}

	onChanges(_error): void {}

	onError(_error): void {}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.event = this._injector.get("event", null);
		this.destruction_lot = this._injector.get("destruction_lot", null);

		this.model.group = this.event.group;
		const inventory: IInventory = this.event.inventory;
		this.model.destruction_lot_id = this.destruction_lot.id;
		this.model.inventory_product_id = inventory.inventory_product_id;
		this.model.inventory_unit_id = inventory.inventory_unit_id;
		this.model.value = Number(
			new Big(this.event.group_events_count ?? 0).times(
				inventory.measurements?.[0]?.value ?? 0,
			),
		);
		this._cd.detectChanges();
	}

	onSubmit() {
		const destruction_event: InventoryDestructionUpdate = {
			...this.model,
		};

		this._store.dispatch(
			layoutActions.openSidenav({
				component: UpdateReasonComponent,
				inputs: {
					onSubmit: (reason, password) => {
						this.updateItem(destruction_event, reason, password);
					},
					form_title: "Update Reason",
					form_title_translation_key: marker("form_title_update_reason"),
					submit_button: "Update",
					submit_button_translation_key: marker("word_update"),
					update_reason_field_label_translation_key: marker(
						"form_field_label_update_reason",
					),
					gmpAskPassword: this._globals.gmp_enabled,
				},
			}),
		);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	updateItem(
		updateDestructionEvent: InventoryDestructionUpdate,
		reason?: string,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`destruction_lots/${this.destruction_lot.id}/events/group`,
				updateDestructionEvent.group,
				{
					...updateDestructionEvent,
					reason,
				},
				{},
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				// eslint-disable-next-line id-length
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.destruction_lot.id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
